import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

window.jQuery = $;
window.$ = $;

const apartmentsInfoSliderInit = () => {
	const $apartmentsInfoSlider = $('.apartmentsInfoSlider');
	const $apartmentsImagesSlider = $('.apartmentsImagesSlider');
	const $sliderCount = $('.sliderCount');

	if (
		typeof $apartmentsInfoSlider !== 'undefined' && $apartmentsInfoSlider.length
	) {
		const updateSliderCounter = (slick, currentIndex) => {
			// if (currentSlide >= )
			let currentSlide = slick.slickCurrentSlide() + 1;
			let slidesCount = slick.slideCount;
			console.log(slidesCount, currentSlide);

			$sliderCount.html(`<span>${currentSlide}</span>&nbsp;| ${slidesCount}`);
		};

		$apartmentsInfoSlider.on('init', (event, slick, currentSlide) => {
			updateSliderCounter(slick);
		});

		$apartmentsInfoSlider.slick({
			prevArrow: '<div class="apartments_slider__arrow_prev"></div>',
			nextArrow: '<div class="apartments_slider__arrow_next"></div>',
			infinite: false,
			// autoplay: true,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			slidesToScroll: 1,
			vertical: true,
			rows: 0,
			asNavFor: $apartmentsImagesSlider,
			verticalSwiping: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						vertical: false,
						verticalSwiping: false,
					},
				},
			],
		});

		$apartmentsImagesSlider.slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			// fade: true,
			dots: false,
			asNavFor: $apartmentsInfoSlider,
			fade: true,
			rows: 0,
			swipe: false,
		});

		$apartmentsInfoSlider.on('afterChange', (event, slick, currentSlide) => {
			updateSliderCounter(slick, currentSlide);
		});
	}
};

export default apartmentsInfoSliderInit;
