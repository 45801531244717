const FormIndex = () => {
	// $('form').submit = (event) => {
	// 	let formData = {
	// 		name: $('#name').val(),
	// 		email: $('#email').val(),
	// 		text: $('#textarea_1').val(),
	// 	};

	// 	$.ajax({
	// 		type: 'POST',
	// 		url: 'process.php',
	// 		data: formData,
	// 		dataType: 'json',
	// 		encode: true,
	// 	}).done = (data) => {
	// 		console.log(data);
	// 		$('.form_success').addClass('form_success_display');
	// 	};

	// 	event.preventDefault();
	// };

	$('#submit').click = () => {
		$('.form_success').addClass('form_success_display');
	};
	console.log('ciao');
};

export default FormIndex;
