import slick from 'slick-carousel';

function leoniPhotoSlider() {
	$('.leoni_photo__slider_total').text($('.leoni_photo__slider_item').length); // eslint-disable-line

	$('.leoni_photo__slider').slick({
		prevArrow: $('.leoni_photo__slider_prev'),
		nextArrow: $('.leoni_photo__slider_next'),
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		arrows: true,
	}).on('beforeChange', function(event, slick, currentSlide, nextSlide){ // eslint-disable-line
		$('.leoni_photo__slider_curent').text(`${nextSlide + 1}`);
	});
}

export default leoniPhotoSlider;
