import { onWindowScroll } from 'utils';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
class Header {
	get SELECTORS() {
		return {
			menuTrigger: '.menuTrigger',
			menuLink: '.nav_list__link',
			section: '.section',
		};
	}

	get CLASSNAMES() {
		return {
			bodyScrollState: 'body--scroll_state',
			bodyOpenMenuState: 'body--open_menu_state',
		};
	}

	constructor() {
		this.$body = document.body;
		this.$menuTrigger = document.querySelector(this.SELECTORS.menuTrigger);
		this.menuLinks = document.querySelectorAll(this.SELECTORS.menuLink);
		this.sectionsArray = document.querySelectorAll(this.SELECTORS.section);
		this.openMenuState = false;
		this.pageScroll = this.pageScroll.bind(this);

		this.init = this.init.bind(this);
		this.init();
	}

	openMenu() {
		if (!this.openMenuState) {
			// console.log('open');
			this.$body.classList.add(this.CLASSNAMES.bodyOpenMenuState);
			this.openMenuState = true;
		} else {
			// console.log('close');
			this.$body.classList.remove(this.CLASSNAMES.bodyOpenMenuState);
			this.openMenuState = false;
		}
	}

	pageScroll(windowScrollTop) {
		if (
			windowScrollTop > 10 &&
			!this.$body.classList.contains(this.CLASSNAMES.bodyScrollState)
		) {
			this.$body.classList.add(this.CLASSNAMES.bodyScrollState);
		}

		if (
			windowScrollTop <= 10 &&
			this.$body.classList.contains(this.CLASSNAMES.bodyScrollState)
		) {
			this.$body.classList.remove(this.CLASSNAMES.bodyScrollState);
		}
	}

	anchorScroll() {
		this.menuLinks.forEach((item, index) => {
			item.addEventListener('click', (e) => {
				e.preventDefault();
				const href = item.getAttribute('href');
				const topY = document.querySelector(href).offsetTop;

				// console.log(href, topY, window);

				gsap.to(window, {
					duration: 0.6,
					scrollTo: {
						y: topY,
						autoKill: false,
					},
					ease: 'none',
				});

				return false;
			});
		});
	}

	init() {
		if (typeof this.$menuTrigger !== 'undefined' && this.$menuTrigger != null) {
			onWindowScroll(this.pageScroll);
			this.$menuTrigger.addEventListener('click', () => {
				this.openMenu();
			});
		}

		if (this.menuLinks.length) {
			this.anchorScroll();
		}
	}
}

export default Header;
