import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class Parallax {
	get SELECTORS() {
		return {
			el: '.parallaxEl',
			parallaxImg: '.parallaxImg',
			parallaxImgIn: '.parallaxImgIn',
		};
	}

	constructor() {
		this.parallaxElements = document.querySelectorAll(this.SELECTORS.el);
		this.parallaxImgArray = document.querySelectorAll(
			this.SELECTORS.parallaxImg,
		);

		this.init = this.init.bind(this);
		this.init();
	}

	initParallaxElAnim() {
		this.parallaxElements.forEach((item, index) => {
			gsap.to(item, {
				scrollTrigger: {
					scrub: true,
				},
				y: (i, target) => {
					let offset = '';
					const { reverse } = target.dataset;
					const { speed } = target.dataset;

					if (reverse) {
						offset = ScrollTrigger.maxScroll(window) * speed;
					} else {
						offset = -ScrollTrigger.maxScroll(window) * speed;
					}
					return offset;
				},
				ease: 'none',
			});
		});
	}

	parallaxImgAnim() {
		this.parallaxImgArray.forEach((item) => {
			const innerImg = item.querySelector(this.SELECTORS.parallaxImgIn);
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					scrub: true,
					pin: false,
				},
			});
			tl.from(innerImg, {
				yPercent: -20,
				ease: 'none',
			}).to(innerImg, {
				yPercent: 20,
				ease: 'none',
			});
		});
	}

	init() {
		if (this.parallaxElements.length > 0) {
			this.initParallaxElAnim();
		}

		if (this.parallaxImgArray.length > 0) {
			this.parallaxImgAnim();
		}
	}
}

export default Parallax;
