// ------------------- imports
import $ from 'jquery';
import { GLOBAL_VARS } from 'utils/constants';
import { documentReady, pageLoad } from 'utils';
import pageWidgetInit from './dev_vendors/dev_widget';
// ------------------- imports###

// ------------------  import components
import 'slick-carousel/slick/slick.scss';

import Header from './components/Header';
import InitMap from './components/InitMap';
import apartmentsInfoSliderInit from './components/apartmentsInfoSlider';
import leoniPhotoSlider from './components/leoniPhotoSlider';
import Parallax from './components/Parallax';
import FormIndex from './components/FormIndex';
// ------------------  import components###

window.jQuery = $;
window.$ = $;

const styles = ['color: #fff', 'background: #cf8e1f'].join(';');
const message = 'Developed by Glivera-team https://glivera-team.com/';
// eslint-disable-next-line no-console
console.info('%c%s', styles, message);

// -------------------  dev widget
// if (GLOBAL_VARS.projectDevStatus) {
// 	pageWidgetInit();
// 	console.log(process.env.NODE_ENV);
// }
// -------------------  dev widget###

// -------------------  global variables

const readyFunc = () => { };

const loadFunc = () => {
	console.log('LOAD');
	const initHeader = new Header();
	const initGoogleMap = new InitMap();
	apartmentsInfoSliderInit();
	leoniPhotoSlider();
	const initParallax = new Parallax();
	FormIndex();
	console.log('test');
};

documentReady(() => {
	readyFunc();
});

pageLoad(() => {
	loadFunc();
});
