import { Loader } from '@googlemaps/js-api-loader';

const InitMap = () => {
	if (document.querySelector('#map')) {
		const loader = new Loader({
			apiKey: document.querySelector('#map').dataset.key,
			version: 'weekly',
			libraries: ['places'],
		});

		const mapOptions = {
			center: {
				lat: parseFloat(document.querySelector('#map').dataset.lat),
				lng: parseFloat(document.querySelector('#map').dataset.lng),
			},
			zoom: parseInt(document.querySelector('#map').dataset.zoom),
		};

		let markersArr = [{ x: parseFloat(document.querySelector('#map').dataset.lat), y: parseFloat(document.querySelector('#map').dataset.lng) }];
		let i;
		let markerIcon;
		loader
			.load()
			.then((google) => {
				let map = new google.maps.Map(document.getElementById('map'), mapOptions);
				markerIcon = {
					url: 'images/marker.svg',
					size: new google.maps.Size(40, 56),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(20, 44),
					scaledSize: new google.maps.Size(40, 56),
				};

				for (i = 0; i < markersArr.length; i += 1) {
					const marker = new google.maps.Marker({
						position: new google.maps.LatLng(markersArr[i].x, markersArr[i].y),
						map,
						icon: markerIcon,
					});
				}
			})
			.catch(e => {
				console.log(e);
			});
	}
};

export default InitMap;
